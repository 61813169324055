import React from "react";
import "../../App.css";
import Footer from "../Footer";
import Cards from "../Cards";

function Meetings() {
  return (
    <>
      {/* <h1 className="meetings">hey</h1> */}
      <Cards></Cards>
      <Footer />
    </>
  );
}

export default Meetings;
