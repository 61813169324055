import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import Meetings from "./components/pages/Meetings";
import FAQ from "./components/pages/FAQ";
import Contact from "./components/pages/Contact";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Accordion from "./components/Accordion.js";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/meetings" element={<Meetings />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
