import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import React from "react";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src="/videos/video9.mp4" autoPlay loop muted playsinline />
      {/* <video autoPlay muted loop className="video">
        <source src="/videos/video9.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}

      <h1>LAS VEGAS BLOCKCHAIN MEETUP</h1>
      <p>learn about blockchain</p>
      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          MEETING INFO
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
