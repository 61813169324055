import "../App.js";
import { Data } from "./Data.js";
import React, { useState } from "react";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";

import "./Accordion.css";

const Accordion = () => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <IconContext.Provider value={{ color: "white", size: "35px" }}>
      <div className="accordion-section">
        <div className="container">
          {Data.map((item, index) => {
            return (
              <>
                <div className="wrap" onClick={() => toggle(index)} key={index}>
                  <p className="faq-question">{item.question}</p>
                  <span className="faq-span">
                    {clicked === index ? <FiMinus /> : <FiPlus />}
                  </span>
                </div>
                {clicked === index ? (
                  <div className="dropdown">
                    <p className="p1">{item.answer}</p>
                  </div>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Accordion;
