export const Data = [
  {
    question: "Who can attend these meetings?",
    answer: "Anyone interested in learning or discussing blockchain!",
  },
  {
    question: "What is LVBM?",
    answer:
      "The Las Vegas Blockchain Meetup is hosted by Nativ Research, UNLV OED, and Blackfire Innovation. This monthly meetup is scheduled for the last Tuesday of every month and is composed of a diverse background of investors, users, institutions, educators, professionals, traders, and more! The Defi edition meetup had 2 presenters who focusd on MEV, EtherScan, Smart Contracts, and NFT Minting.",
  },
  {
    question: "What is Nativ Research?",
    answer:
      "Nativ Research is an independent Crypto Research Firm that is focused on accelerating blockchain adoption in Las Vegas though collaboration and education. Our goals are to introduce blockchain to those who are interested and integrate the future of finance within the ecosystem of the world's playground which is known as Las Vegas. ",
  },
];
