import React from "react";
import "../../App.css";
import "../../App.js";
import Accordion from "../Accordion";
import Footer from "../Footer";

function FAQ() {
  return (
    <>
      <Accordion />
      <Footer />
    </>
  );
}

export default FAQ;
