import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1>Next Meetup: </h1>
      <h2>
        {" "}
        May 31st, 2022 <br></br>2 - 5 PM
        <br></br>@ Black Fire Innovation 8400 West Sunset Road,
        <br></br> Las Vegas NV 89113
      </h2>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/meeting.jpg"
              text="Hosted by the UNLV Office of Economic Development and Nativ Research, this is a monthly meetup event that welcomes all experience levels and interests in Blockchain technology and Cryptocurrency. From builders and developers to marketing professionals interested in how new tech might influence their businesses, these meetups are attended by a knowledge base. This month's topic is financial technology (fin tech)."
              label="Topic: FinTech"
              path="/meetings"
            />
            {/* <CardItem
              src="images/meeting.jpg"
              text="Hosted by the UNLV Office of Economic Development and Nativ Research, this is a monthly meetup event that welcomes all experience levels and interests in Blockchain technology and Cryptocurrency. From builders and developers to marketing professionals interested in how new tech might influence their businesses, these meetups are attended by a knowledge base. This month's topic is financial technology (fin tech)."
              label="Topic: FinTech"
              path="/meetings"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/meeting.jpg"
              text="Hosted by the UNLV Office of Economic Development and Nativ Research, this is a monthly meetup event that welcomes all experience levels and interests in Blockchain technology and Cryptocurrency. From builders and developers to marketing professionals interested in how new tech might influence their businesses, these meetups are attended by a knowledge base. This month's topic is financial technology (fin tech)."
              label="Topic: FinTech"
              path="/meetings"
            />
            <CardItem
              src="images/meeting.jpg"
              text="Hosted by the UNLV Office of Economic Development and Nativ Research, this is a monthly meetup event that welcomes all experience levels and interests in Blockchain technology and Cryptocurrency. From builders and developers to marketing professionals interested in how new tech might influence their businesses, these meetups are attended by a knowledge base. This month's topic is financial technology (fin tech)."
              label="Topic: FinTech"
              path="/meetings"
            />
            <CardItem
              ssrc="images/meeting.jpg"
              text="Hosted by the UNLV Office of Economic Development and Nativ Research, this is a monthly meetup event that welcomes all experience levels and interests in Blockchain technology and Cryptocurrency. From builders and developers to marketing professionals interested in how new tech might influence their businesses, these meetups are attended by a knowledge base. This month's topic is financial technology (fin tech)."
              label="Topic: FinTech"
              path="/meetings"
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
