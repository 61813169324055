import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div class="footer-links"></div>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            {/* <Link to="/" className="social-logo">
              <i class="fa-solid fa-l"></i>
              <i class="fa-solid fa-v"></i>
              <i class="fa-solid fa-dice-d6   "></i>
              <i class="fa-solid fa-link"></i>
            </Link> */}
          </div>
          <div className="footer--title">
            <h1>LV Blockchain Meetup</h1>
          </div>
          <div className="footer--text">
            <h1>
              @ Black Fire Innovation <br></br> UNLV Harry Reid Research &
              Technology Park <br></br> 8400 West Sunset Road, Suite 400 Las
              Vegas, NV 89113
            </h1>
          </div>
          <div class="social-icons">
            <a
              class="social-icon-link youtube"
              href="https://www.youtube.com/channel/UCmJPIH1k2puNIWlTmbHkMOg"
              target="_blank"
            >
              <i class="fab fa-youtube" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
